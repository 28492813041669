<template>
  <v-form ref="personalDataForm" v-model="isFormValid" class="multi-col-validation" @submit.prevent="saveChanges">
    <v-row class="align-center text-base px-md-3">
      <v-col cols="12" sm="3" md="3" class="mb-0">
        <label for="radio-mr">{{ $t('formLabelSalutation') }}</label>
      </v-col>
      <v-col cols="12" sm="9" md="9" class="pt-0 mt-0">
        <v-radio-group id="radio-mr" v-model="personalData.salutation" row hide-details class="mt-0">
          <v-radio :label="$t('formLabelSalutationMale')" :value="SALUTATION_MR"></v-radio>
          <v-radio :label="$t('formLabelSalutationFemale')" :value="SALUTATION_MRS"></v-radio>
          <v-radio :label="$t('formLabelSalutationDiverse')" :value="SALUTATION_DIV"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" md="3" class="d-none d-sm-flex">
        <label for="firstname">{{ $t('formLabelFirstName') }}</label>
      </v-col>
      <v-col md="9" cols="12">
        <v-text-field
          id="firstname"
          v-model="personalData.firstName"
          :label="$t('formLabelFirstName')"
          :placeholder="$t('formLabelFirstName')"
          :rules="[validators.required]"
          dense
          outlined
        >
          <template #message="{ message }">
            {{ $t(message) }}
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="d-none d-sm-flex">
        <label for="lastname">{{ $t('formLabelLastName') }}</label>
      </v-col>
      <v-col md="9" cols="12">
        <v-text-field
          id="lastname"
          v-model="personalData.lastName"
          :label="$t('formLabelLastName')"
          :rules="[validators.required]"
          dense
          outlined
        >
          <template #message="{ message }">
            {{ $t(message) }}
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="d-none d-sm-flex">
        <label for="dob">{{ $t('formLabelDateOfBirth') }}</label>
      </v-col>
      <v-col md="9" cols="12">
        <v-menu
          ref="menuref"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-bind="attrs"
              id="dob"
              v-model="personalData.dateOfBirthFormatted"
              :rules="[validators.ageValidator]"
              :label="$t('formLabelDateOfBirth')"
              readonly
              outlined
              dense
              :prepend-inner-icon="icons.mdiCalendar"
              v-on="on"
            >
              <template #message="{ message }">
                {{ $t(message, { minAge: 18 }) }}
              </template>
            </v-text-field>
          </template>

          <v-date-picker
            ref="picker"
            v-model="personalData.dateOfBirth"
            :max="new Date().toISOString().substr(0, 10)"
            min="1950-01-01"
            color="primary"
            :locale="currentLang === 'en' ? 'US-en' : 'De-de'"
            @change="save"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="3" class="d-none d-sm-flex">
        <label for="city-of-birth">{{ $t('formLabelPlaceOfBirth') }}</label>
      </v-col>
      <v-col md="9" cols="12">
        <v-text-field
          id="city-of-birth"
          v-model="personalData.cityOfBirth"
          dense
          outlined
          :label="$t('formLabelPlaceOfBirth')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="d-none d-sm-flex">
        <label for="country-birth">{{ $t('formLabelCountryOfBirth') }}</label>
      </v-col>
      <v-col md="9" cols="12">
        <v-select
          id="country-birth"
          v-model="personalData.countryOfBirth"
          :label="$t('formLabelCountryOfBirth')"
          :items="countries"
          dense
          outlined
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="12" md="3" class="d-none d-sm-flex">
        <label for="street-address">{{ $t('formLabelStreetAddress') }}</label>
      </v-col>
      <v-col md="9" cols="12">
        <v-text-field
          id="street-address"
          v-model="personalData.streetAddress"
          dense
          outlined
          :label="$t('formLabelStreetAddress')"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="3" class="d-none d-sm-flex">
        <label for="city">{{ $t('formLabelCity') }}</label>
      </v-col>
      <v-col md="9" cols="12">
        <v-text-field
          id="city"
          v-model="personalData.city"
          dense
          outlined
          :label="$t('formLabelCity')"
          :rules="[validators.required]"
        >
          <template #message="{ message }">
            {{ $t(message) }}
          </template>
        </v-text-field>
      </v-col>

      <v-col cols="12" md="3" class="d-none d-sm-flex">
        <label for="postal-code">{{ $t('formLabelPostalCode') }}</label>
      </v-col>
      <v-col md="9" cols="12">
        <v-text-field
          id="postal-code"
          v-model="personalData.postalCode"
          dense
          outlined
          :label="$t('formLabelPostalCode')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="d-none d-sm-flex">
        <label for="country">{{ $t('formLabelCountry') }}</label>
      </v-col>
      <v-col cols="12" md="9">
        <v-select
          id="country"
          v-model="personalData.country"
          :items="countries"
          :label="$t('formLabelCountry')"
          dense
          outlined
          hide-details
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="activeRole == 'ROLE_BAV_MANAGER'" class="align-center text-base px-md-3">
      <v-col cols="12" md="3" class="d-none d-sm-flex">
        <label for="personal-number">{{ $t('formLabelPersonalNumber') }}</label>
      </v-col>
      <v-col md="9" cols="12">
        <v-text-field
          id="personal-number"
          v-model="personalData.personalNumber"
          dense
          outlined
          :label="$t('formLabelPersonalNumber')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="align-center text-base px-md-3">
      <v-col cols="12" md="3" class="d-none d-sm-flex">
        <label for="email-personal">{{ $t('formLabelEmailPersonal') }}</label>
      </v-col>
      <v-col md="9" cols="12">
        <v-text-field
          id="email-personal"
          v-model="personalData.emailPersonal"
          :rules="[validators.emailValidator]"
          dense
          outlined
          :label="$t('formLabelEmailPersonal')"
          :prepend-inner-icon="icons.mdiEmailOutline"
        >
          <template #message="{ message }">
            {{ $t(message) }}
          </template>
        </v-text-field>
      </v-col>

      <v-col cols="12" md="3" class="d-none d-sm-flex">
        <label for="email-business">{{ $t('formLabelEmailBusiness') }}</label>
      </v-col>
      <v-col md="9" cols="12">
        <v-text-field
          id="email-business"
          v-model="personalData.emailBusiness"
          :rules="[validators.required, validators.emailValidator]"
          dense
          outlined
          :label="$t('formLabelEmailBusiness')"
          :prepend-inner-icon="icons.mdiAt"
        >
          <template #message="{ message }">
            {{ $t(message) }}
          </template>
        </v-text-field>
      </v-col>

      <v-col cols="12" md="3" class="d-none d-sm-flex">
        <label for="phone">{{ $t('formLabelPhone') }}</label>
      </v-col>
      <v-col md="9" cols="12">
        <vue-tel-input-vuetify
          v-model="personalData.phoneNumber"
          validate-on-blur
          :label="$t('formLabelPhone')"
          defaultCountry="de"
          inputId="phone"
          dense
          mode="international"
          :rules="[validators.required]"
          :valid-characters-only="true"
          :inputOptions="{ showDialCode: false }"
          outlined
        >
          <template #message="{ message }">
            {{ $t(message) }}
          </template>
        </vue-tel-input-vuetify>
      </v-col>
      <v-col cols="12" md="3" class="d-none d-sm-flex">
        <label for="contact-method">{{ $t('CONTACT_METHOD') }}</label>
      </v-col>
      <v-col md="9" cols="12">
        <v-select
          id="contact-method"
          v-model="personalData.contactMethod"
          :label="$t('CONTACT_METHOD')"
          :items="contactMethodOptions"
          dense
          outlined
          hide-details
        ></v-select>
      </v-col>
      <v-col v-if="activeRole === 'ROLE_BAV_MANAGER'" cols="12" md="3" class="d-none d-sm-flex">
        <label for="language-preference">{{ $t('formLabelLanguagePreference') }}</label>
      </v-col>
      <v-col v-if="activeRole === 'ROLE_BAV_MANAGER'" md="9" cols="12">
        <v-select
          id="language-preference"
          v-model="personalData.languagePreference.language"
          :label="$t('formLabelLanguagePreference')"
          :items="languageOptions"
          dense
          outlined
          hide-details
          @change="allowSave = true"
        ></v-select>
      </v-col>
      <v-card-actions class="v-card-actions--custom">
        <v-col sm="3" class="px-0">
          <v-btn block type="submit" color="primary" class="mt-4" :disabled="!allowSave">
            {{ $t('buttonSaveData') }}
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-row>
  </v-form>
</template>

<script>
import { ref, toRefs, watch, getCurrentInstance, computed, onMounted } from '@vue/composition-api';
import {
  required,
  emailValidator,
  phoneNumberValidator,
  ageValidator,
  localPhoneNumberValidator,
} from '@core/utils/validation';
import { mdiCalendar, mdiAt, mdiCellphone, mdiEmailOutline } from '@mdi/js';
import moment from 'moment';
import { updateEmployeePersonalData } from '../../api/employee';
import {
  createCountrySelection,
  SALUTATION_TRANSLATION_MAP,
  SALUTATION_DIV,
  SALUTATION_MR,
  SALUTATION_MRS,
  PHONE,
  PHONE_EMAIL,
  EMAIL,
} from '../../constants';

export default {
  props: {
    personalData: {
      type: Object,
      required: false,
    },
    companyData: {
      type: Object,
    },
  },

  setup(props) {
    const vm = getCurrentInstance().proxy;
    const { activeRole } = vm.$store.getters;

    const { personalData } = toRefs(props);

    const date = ref(null);
    const menu = ref(false);
    const menuStartDate = ref(false);
    const menuStartRef = ref(null);
    const picker = ref(null);
    const menuref = ref(null);
    const allowSave = ref(false);
    const personalDataForm = ref(null);
    const isFormValid = ref(false);
    const currentLang = computed(() => vm.$store.state.app.currentLang);

    const countries = computed(() => createCountrySelection(currentLang.value === 'en' ? 'en' : 'de'));

    const salutationOptions = [
      {
        text: vm.$t(SALUTATION_TRANSLATION_MAP[SALUTATION_MR]),
        value: SALUTATION_MR,
      },
      {
        text: vm.$t(SALUTATION_TRANSLATION_MAP[SALUTATION_MRS]),
        value: SALUTATION_MRS,
      },
      {
        text: vm.$t(SALUTATION_TRANSLATION_MAP[SALUTATION_DIV]),
        value: SALUTATION_DIV,
      },
    ];

    const contactMethodOptions = computed(() => [
      {
        text: vm.$t(PHONE),
        value: PHONE,
      },
      {
        text: vm.$t(EMAIL),
        value: EMAIL,
      },
      {
        text: vm.$t(PHONE_EMAIL),
        value: PHONE_EMAIL,
      },
    ]);
    const languageOptions = ref([
      {
        text: 'DE',
        value: 'de',
      },
      {
        text: 'EN',
        value: 'en',
      },
    ]);
    watch(menu, (val) => {
      // eslint-disable-next-line no-return-assign
      val && setTimeout(() => (picker.value.activePicker = 'YEAR'));
    });

    watch(currentLang.value, () => {
      countries.value = createCountrySelection(currentLang.value === 'en' ? 'en' : 'de');
    });

    watch(
      personalData,
      () => {
        allowSave.value = true;
        vm.$emit('edit');
        vm.$emit('validation', allowSave.value && isFormValid.value);
      },
      { deep: true },
    );

    const save = (d) => {
      menuref.value.save(d);
      formatBirthDate();
    };

    const saveChanges = () => {
      const isValid = personalDataForm.value.validate();
      if (!isValid) return;

      updateEmployeePersonalData(vm.$route.params.id, personalData.value).then(() => {
        vm.$emit('reset');
      });
    };

    const reset = () => {
      vm.$emit('reset');
      vm.$nextTick(() => {
        allowSave.value = false;
      });
    };

    const formatBirthDate = () => {
      personalData.value.dateOfBirthFormatted = moment(personalData.value.dateOfBirth).format('DD.MM.YYYY');
    };

    onMounted(() => {
      // if (personalData.value.employmentStartDate) {
      //   formatStartDate();
      // } else {
      //   personalData.value.employmentStartDateFormatted = "";
      // }

      if (personalData.value.dateOfBirth) {
        formatBirthDate();
      }
    });

    return {
      date,
      menu,
      menuStartDate,
      picker,
      menuref,
      menuStartRef,
      save,

      saveChanges,
      salutationOptions,
      allowSave,
      reset,
      personalDataForm,
      isFormValid,
      countries,
      contactMethodOptions,
      currentLang,
      activeRole,
      SALUTATION_MR,
      SALUTATION_MRS,
      SALUTATION_DIV,
      languageOptions,

      // icons
      icons: {
        mdiCalendar,
        mdiAt,
        mdiCellphone,
        mdiEmailOutline,
      },
      validators: {
        required,
        phoneNumberValidator,
        localPhoneNumberValidator,
        emailValidator,
        ageValidator,
      },
    };
  },
};
</script>
<style lang="scss">
.v-card-actions--custom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: -10px;
  .v-btn + .v-btn {
    margin-right: -20px !important;
  }
}
.vue-tel-input-vuetify {
  align-items: flex-start;
}
.vue-tel-input-vuetify .v-input--has-state {
  transform: translateY(0px);
}
</style>
